import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Home/Home";

import ContactM from "./Contact/ContactM";
import AboutM from "./AboutUs/AboutM";
import Servicem from "./Services/Servicem";
import React from "./Technology/React";
import Flutter from "./TechnologyHover/Mobile/Flutter/Flutter"
import Angular from "./TechnologyHover/Angular";
import Vue from "./TechnologyHover/Vue";
import Node from "./TechnologyHover/Backend/NodeJs/Node";
import Java from "./TechnologyHover/Backend/Java/Java";
import Python from "./TechnologyHover/Backend/Python/Python"

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutM />} />
          <Route path="/contact" element={<ContactM />} />
          <Route path="/service" element={<Servicem />} />
          <Route path="/React" element={<React />} />
          <Route path="/Flutter" component={Flutter} element={<Flutter/>} />
          <Route path="/angular" component={Angular} element={<Angular/>} />
          <Route path="/Vue" component={Vue} element={<Vue/>} />
          <Route path="/Node" component={Vue} element={<Node/>} />
          <Route path="/Python" component={Vue} element={<Python/>} />
          <Route path="/Java" component={Vue} element={<Java/>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
