import React from "react";
import { Grid } from "@mui/material";

export default function PythonC() {
  return (
    <div>
      <div className="degree-main">
        <div className="degree-container">
          <h6 className="rea">Popular Vue.js Pairings</h6>
          <h2>Why opt for our Indian remote Python developers team</h2>
          <p className="pe">
            In choosing our Indian remote Python developers team, you benefit
            from
          </p>
          <div className="degree-card">
            <Grid
              container
              spacing={3}
              sx={{ paddingLeft: 3, paddingRight: 3 }}
            >
              <Grid item xs={12} sm={12} md={6}>
                <div className="techd  ">
                  <h3 className="degree-center techa">
                    <span className="dot" />
                    1. Cost-Effective Solutions
                  </h3>
                  <p className="degree-pra left">
                    Our Indian developers offer cost-effective solutions without
                    compromising on quality, providing a budget-friendly option
                    for your Python development needs.
                  </p>
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <div className="techd ">
                  <h3 className="degree-center techa">
                    {" "}
                    <span className="dot" />
                    2. Pool of Skilled Professionals
                  </h3>
                  <p className="degree-pra left">
                    India boasts a vast pool of skilled Python developers with
                    diverse experience, ensuring you have access to a talent
                    pool capable of handling a wide range of project
                    requirements.
                  </p>{" "}
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                {" "}
                <div className="techd">
                  <h3 className="degree-center techa">
                    {" "}
                    <span className="dot" />
                    3. Time Zone Compatibility
                  </h3>
                  <p className="degree-pra left">
                    With overlapping work hours between India and many other
                    regions, our remote developers facilitate smoother
                    collaboration, reducing communication gaps and ensuring
                    timely project progress updates.
                  </p>{" "}
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <div className="techd ">
                  <h3 className="degree-center techa">
                    {" "}
                    <span className="dot" />
                    4. English Proficiency
                  </h3>
                  <p className="degree-pra left">
                    Our developers are proficient in English, ensuring effective
                    communication and understanding of project requirements,
                    leading to streamlined collaboration and project success
                  </p>{" "}
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                {" "}
                <div className="techd ">
                  <h3 className="degree-center techa">
                    {" "}
                    <span className="dot" />
                    5. Proven Track Record
                  </h3>
                  <p className="degree-pra left">
                    Benefit from our developers' proven track record in
                    delivering successful Python projects, backed by
                    testimonials and case studies that showcase their expertise
                    and reliability.
                  </p>{" "}
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                {" "}
                <div className="techd">
                  <h3 className="degree-center techa">
                    {" "}
                    <span className="dot" />
                    Adherence to Coding Standards
                  </h3>
                  <p className="degree-pra left">
                    Our developers follow industry-standard coding practices and
                    adhere to best practices in Python development, ensuring
                    code quality, maintainability, and scalability.
                  </p>{" "}
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
}
