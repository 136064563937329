import React from "react";
import { Grid } from "@mui/material";

export default function JavaC() {
  return (
    <div>
      <div className="degree-main">
        <div className="degree-container">
          <h6 className="rea">Popular Vue.js Pairings</h6>
          <h2>Why opt for our Indian remote Java developers team</h2>
          <p className="pe">
            In choosing our Indian remote Java developers team, you gain access
            to a pool of skilled professionals dedicated to ensuring your
            project's success. With benefits such as cost-effectiveness,
            timezone advantages, and cultural compatibility, our team is poised
            to deliver unparalleled value.
          </p>
          <div className="degree-card">
            <Grid
              container
              spacing={3}
              sx={{ paddingLeft: 3, paddingRight: 3 }}
            >
              <Grid item xs={12} sm={12} md={6}>
                <div className="techd  ">
                  <h3 className="degree-center techa">
                    <span className="dot" />
                    Cost-Effective Solutions
                  </h3>
                  <p className="degree-pra left">
                    Benefit from cost-effective development solutions without
                    compromising on the quality of work, making our Indian
                    remote Java developers team a budget-friendly choice.
                  </p>
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <div className="techd ">
                  <h3 className="degree-center techa">
                    {" "}
                    <span className="dot" />
                    Timezone Advantage
                  </h3>
                  <p className="degree-pra left">
                    Leverage the timezone difference to your advantage, ensuring
                    round-the-clock development progress and quicker turnaround
                    times for issue resolution and project updates.
                  </p>{" "}
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                {" "}
                <div className="techd">
                  <h3 className="degree-center techa">
                    {" "}
                    <span className="dot" />
                    Cultural Compatibility
                  </h3>
                  <p className="degree-pra left">
                    Our Indian developers blend technical expertise with a deep
                    understanding of global business culture, ensuring a
                    seamless integration with your existing team and project
                    requirements.
                  </p>{" "}
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <div className="techd ">
                  <h3 className="degree-center techa">
                    {" "}
                    <span className="dot" />
                    Large Talent Pool
                  </h3>
                  <p className="degree-pra left">
                    Tap into a vast talent pool of highly skilled and
                    experienced Java developers, providing you with the
                    flexibility to choose professionals with the exact skill set
                    your project demands.
                  </p>{" "}
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                {" "}
                <div className="techd ">
                  <h3 className="degree-center techa">
                    {" "}
                    <span className="dot" />
                    Proven Track Record
                  </h3>
                  <p className="degree-pra left">
                    Rely on our team's proven track record of successfully
                    delivering Java projects, demonstrating their ability to
                    meet and exceed client expectations.
                  </p>{" "}
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                {" "}
                <div className="techd">
                  <h3 className="degree-center techa">
                    {" "}
                    <span className="dot" />
                    Communication Infrastructure
                  </h3>
                  <p className="degree-pra left">
                    Enjoy a robust communication infrastructure that facilitates
                    clear and regular communication, fostering collaboration and
                    transparency throughout the development process.
                  </p>{" "}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                {" "}
                <div className="techd">
                  <h3 className="degree-center techa">
                    {" "}
                    <span className="dot" />
                    Adherence to Deadlines
                  </h3>
                  <p className="degree-pra left">
                    Our Indian remote Java developers are committed to meeting
                    project deadlines, ensuring timely delivery without
                    compromising on the quality of work.
                  </p>{" "}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                {" "}
                <div className="techd">
                  <h3 className="degree-center techa">
                    {" "}
                    <span className="dot" />
                    Diverse Industry Experience
                  </h3>
                  <p className="degree-pra left">
                    Benefit from our team's diverse industry experience,
                    allowing them to understand the unique challenges and
                    requirements of different sectors, resulting in tailored and
                    effective solutions
                  </p>{" "}
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
}
