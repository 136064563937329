import React from "react";
import { Grid } from "@mui/material";
import vueicon1 from "./VueImg/vuejs-icons-01.png";
import vueicon2 from "./VueImg/vuejs-icons-02.png";
import vueicon3 from "./VueImg/vuejs-icons-03.png";
import vueicon4 from "./VueImg/vuejs-icons-04.png";
import vueicon5 from "./VueImg/vuejs-icons-05.png";
import vueicon6 from "./VueImg/vuejs-icons-06.png";
export default function VueB() {
  return (
    <div className="degree-main cl">
      <div className="degree-container">
        <h6 className="rea">Remote Vue JS Experts for Agile Scaling</h6>
        <h2>Experienced Vue.js Developer Ready to Elevate Your Project</h2>
        <p className="pe">
          Elevate your web applications with the expertise of a remote Vue.js
          developer. Proficient in front-end development, I specialize in
          creating seamless and responsive user interfaces.
        </p>
        <div className="degree-card reed">
          <Grid container spacing={3} sx={{ paddingLeft: 3, paddingRight: 3 }}>
            <Grid item xs={12} sm={12} md={4}>
              <div className="grid-item-box ">
                <h3 className="degree-center tech">
                  <img src={vueicon1} alt="" />
                  Enterprising Vue.js Applications
                </h3>
                <p className="degree-pra">
                  Our Vue.js experts comprehend the dynamic nature of your
                  business and market landscape. As a result, they diligently
                  work on your project, offering top-tier solutions and services
                  to businesses of all sizes, irrespective of their geographical
                  location.
                </p>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <div className="grid-item-box ">
                <h3 className="degree-center tech">
                  <img src={vueicon2} alt={""} />
                  Development of Single-page Apps
                </h3>
                <p className="degree-pra">
                  Our dedicated team of Vue.js developers is committed to
                  setting you apart from the competition. At Your Team In India,
                  our professionals craft single-page web applications that
                  captivate users, ensuring you're ahead in the game.
                </p>{" "}
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              {" "}
              <div className="grid-item-box ">
                <h3 className="degree-center tech">
                  {" "}
                  <img src={vueicon3} alt={""} />
                  Tailored Vue.js Web Apps
                </h3>
                <p className="degree-pra">
                  Leverage our Vue.js app development to create custom
                  applications within specified timelines without compromising
                  quality. Our specialists excel in crafting apps across various
                  domains.Custom Consultation for Android Apps
                </p>{" "}
              </div>
            </Grid>
          </Grid>
        </div>
        <div className="degree-card">
          <Grid container spacing={3} sx={{ paddingLeft: 3, paddingRight: 3 }}>
            <Grid item xs={12} sm={12} md={4}>
              <div className="grid-item-box ">
                <h3 className="degree-center tech ">
                  {" "}
                  <img src={vueicon4} alt={""} />
                  Vue.js Support & Maintenance
                </h3>
                <p className="degree-pra">
                  Our highly skilled developers offer comprehensive maintenance
                  and support using advanced Vue.js development tools. Their
                  commitment extends beyond app development; they provide 24/7
                  support and regular updates.
                </p>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <div className="grid-item-box ">
                <h3 className="degree-center tech">
                  {" "}
                  <img src={vueicon5} alt={""} />
                  Vue.js Migration & Integration Services
                </h3>
                <p className="degree-pra">
                  In recent years, our experts have diligently worked on various
                  third-party and API integrations to facilitate seamless
                  communication between frontend and backend systems.
                </p>{" "}
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              {" "}
              <div className="grid-item-box ">
                <h3 className="degree-center tech">
                  {" "}
                  <img src={vueicon6} alt={""} />
                  Blockchain Mobile Applications
                </h3>
                <p className="degree-pra">
                  Engage our experts to assist in developing robust,
                  cross-platform native mobile or web-based applications.
                  Leveraging the Vue Native Command Line Interface, our
                  professionals create highly interactive Vue native
                  applications.
                </p>{" "}
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}
