import React from "react";
import { Grid } from "@mui/material";
import NodeB from "./NodeB";
import NodeC from "./NodeC";
import Teche from "../../../Technology/Teche";
import Header from "../../../Home/Header";
import { Helmet } from "react-helmet";
import FormButton from "../../../Home/FormButton";
import ProcessOf from "../../../Services/ProcessOf";
// import Lottie from "lottie-react";
// import NodeMain from "./NodeImg/Animation - 1706010129554.json"
// import imgmain from "./NodeImg/services-banner-dft.png"
import NodeMain from "./NodeImg/NodeJs-banner.png"

export default function Node() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Node Js | Destino Infotech</title>
        <meta name="description" content="Helmet application" />
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <Header />
      <div className="ser-main">
        <div className="ser-container">
          <Grid container spacing={3} sx={{ paddingLeft: 3, paddingRight: 3 }}>
            <Grid item xs={12} sm={12} md={6}>
              <h1 className="ser-head">
                Hire
                <span className="color">
                  {" "}
                  Node JS <br />
                  Developers{" "}
                </span>{" "}
              </h1>
             
              <p className="ser-pra">
                Accelerate your project timelines with the rapid onboarding of
                highly skilled Node.js developers. Our efficient hiring process
                ensures swift access to professionals proficient in Node.js,
                enabling your team to meet tight deadlines and surpass project
                expectations. With a focus on speed and quality, our Node.js
                developers bring the expertise needed for robust and scalable
                web applications.
              </p>
              <h5>Efficient Onboarding Process</h5>
              <h5>Smooth Integration Support</h5>
              <h5>Performance-Oriented Solutions</h5>
              <h5>Flexible Engagement Models</h5>
              <h5>Customized Development Approaches</h5>
              <FormButton />
            </Grid>
            <Grid item xs={12} sm={12} md={6} className="nodejs">
              {" "}
             
            <img src={NodeMain} alt={""}/>
            </Grid>
          </Grid>
        </div>
      </div>
      <NodeB />
      <ProcessOf/>
      <NodeC />
      

      <Teche />
    </>
  );
}
