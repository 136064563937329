import React from "react";
import { Grid } from "@mui/material";
import VueC from "../../Vue/VueC";
import icon1 from "./Pythonimg/Python-01.png";
import icon2 from "./Pythonimg/Python-02.png";
import icon3 from "./Pythonimg/Python-03.png";
import icon4 from "./Pythonimg/Python-04.png";
import icon5 from "./Pythonimg/Python-05.png";
import icon6 from "./Pythonimg/Python-06.png";

export default function PythonB() {
  return (
    <div className="degree-main cl">
      <div className="degree-container">
        <h6 className="rea">Remote Python Developers for Agile Scaling:</h6>
        <h2>
          Remote Python Developer for Hire: Expert in Crafting Scalable
          Solutions for Your Coding Challenges
        </h2>
        <p className="pe">
          Harness the versatility of Python with a skilled remote developer. I
          specialize in crafting efficient and scalable solutions, from web
          applications to automation scripts. With a strong foundation in Python
          frameworks and libraries, I bring a passion for clean code and
          innovation to your projects.
        </p>
        <div className="degree-card reed">
          <Grid container spacing={3} sx={{ paddingLeft: 3, paddingRight: 3 }}>
            <Grid item xs={12} sm={12} md={4}>
              <div className="grid-item-box ">
                <h3 className="degree-center tech">
                  <img src={icon1} alt="" />
                  Expertise in Python Development
                </h3>
                <p className="degree-pra">
                  Our remote Python developers bring extensive expertise in
                  Python programming, ensuring proficiency in developing
                  scalable and efficient solutions. With a strong foundation in
                  Python, they contribute to building robust applications
                  tailored to your specific requirements.
                </p>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <div className="grid-item-box ">
                <h3 className="degree-center tech">
                  <img src={icon2} alt={""} />
                  Agile Development Practices
                </h3>
                <p className="degree-pra">
                  Embracing Agile methodologies, our developers prioritize
                  collaboration, adaptability, and iterative development. This
                  allows for seamless integration with your team, quick response
                  to changing project needs, and the delivery of high-quality
                  software within specified timelines.
                </p>{" "}
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              {" "}
              <div className="grid-item-box ">
                <h3 className="degree-center tech">
                  {" "}
                  <img src={icon3} alt={""} />
                  Scalability and Performance Optimization
                </h3>
                <p className="degree-pra">
                  Our developers are adept at designing and implementing
                  scalable solutions, optimizing performance for projects of
                  varying complexities. Through thorough testing and
                  optimization strategies, they ensure your Python applications
                  can handle increased workloads without compromising
                  performance.
                </p>{" "}
              </div>
            </Grid>
          </Grid>
        </div>
        <div className="degree-card">
          <Grid container spacing={3} sx={{ paddingLeft: 3, paddingRight: 3 }}>
            <Grid item xs={12} sm={12} md={4}>
              <div className="grid-item-box ">
                <h3 className="degree-center tech ">
                  {" "}
                  <img src={icon4} alt={""} />
                  Effective Communication and Collaboration
                </h3>
                <p className="degree-pra">
                  Clear communication is paramount in remote development teams.
                  Our Python developers are skilled in maintaining transparent
                  and efficient communication channels, fostering collaboration
                  through tools like video conferencing, chat platforms, and
                  project management systems.
                </p>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <div className="grid-item-box ">
                <h3 className="degree-center tech">
                  {" "}
                  <img src={icon5} alt={""} />
                  Continuous Integration and Deployment (CI/CD)
                </h3>
                <p className="degree-pra">
                  Leveraging CI/CD pipelines, our remote Python developers
                  automate the software delivery process, reducing manual errors
                  and accelerating the deployment cycle. This results in a more
                  reliable and efficient development workflow, enhancing the
                  overall productivity of your team.
                </p>{" "}
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              {" "}
              <div className="grid-item-box ">
                <h3 className="degree-center tech">
                  {" "}
                  <img src={icon6} alt={""} />
                  Security-Centric Development
                </h3>
                <p className="degree-pra">
                  Security is a top priority in our development practices. Our
                  Python developers implement robust security measures,
                  following best practices to safeguard your applications
                  against potential vulnerabilities and threats, ensuring a
                  secure and trustworthy final product.
                </p>{" "}
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <VueC />
    </div>
  );
}
