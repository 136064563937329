import React from "react";
import "./Services.scss";

import { Grid } from "@mui/material";
import Lottie from "lottie-react";
import Con from "./S-Img/connect.json";
import Dev2 from "./S-Img/dev3 (1).json";
import Ontime from "./S-Img/On-Time Delivery.json";
import Project from "./S-Img/project.json";


export default function ProcessOf() {
  return (
    <div className="pro-main">
      <div className="pro-container">
        <h6 className="rea">Streamlined Hiring for Your Project's Success</h6>
        <h1>It's Easy to Hire Developers with Destino</h1>
        <p>
          At Destino, our hiring process is designed to make securing top-tier
          developers effortless. With a user-friendly interface and a rigorous
          selection of the finest talent, we ensure a seamless onboarding
          experience. Say goodbye to hiring hassles and hello to a skilled team
          ready to bring your project to life.
        </p>
        <div className="how">
          <div className="process-line"></div>
          <Grid container spacing={2}>
            {/* Feature Section 1 */}
            <Grid item xs={12} sm={3} className="k">
              <div className="dev-img">
                <Lottie animationData={Con} />
              </div>
              <h2>Get Connect</h2>
            </Grid>

            <Grid item xs={12} sm={3}>
              <div className="dev-img">
                <Lottie animationData={Project} />
              </div>
              <h2>Project & Screening</h2>
            </Grid>

            <Grid item xs={12} sm={3}>
              <div className="dev-img">
                <Lottie animationData={Dev2} className="k"/>
              </div>
              <h2>Dedicated Developer</h2>
            </Grid>
            <Grid item xs={12} sm={3}>
              <div className="dev-img">
                <Lottie animationData={Ontime} />
              </div>
              <h2>Development & Customization</h2>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}
