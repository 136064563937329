import React from 'react'
import { Grid } from '@mui/material'
import { Helmet } from 'react-helmet'
import Header from '../../../Home/Header'
import FormButton from '../../../Home/FormButton'
import Teche from '../../../Technology/Teche'
import JavaB from './JavaB'
import JavaC from './JavaC'
import ProcessOf from '../../../Services/ProcessOf'
import JavaMain from "./JavaImg/Java-banner.png"
export default function Java() {
  return (
    <>
    <Helmet>
               <meta charSet="utf-8" />
               <title>Java  | Destino Infotech</title>
               <meta name="description" content="Helmet application" />
               <link rel="canonical" href="http://mysite.com/example" />
           </Helmet>
     <Header />
     <div className="ser-main">
       <div className="ser-container">
         <Grid container spacing={3} sx={{ paddingLeft: 3, paddingRight: 3 }}>
           <Grid item xs={12} sm={12} md={6}>
             <h1 className="ser-head">
             Hiring
               <span className="color">
                 {" "}
                 Java<br />
                 Developers{" "}
               </span>{" "} Rapidly
             </h1>
             <p className="ser-pra">
             Expedite your project timelines by swiftly bringing aboard seasoned Java developers. Our efficient hiring process ensures the rapid integration of skilled professionals into your team, enabling you to meet tight deadlines and exceed project expectations. With a strong foundation in Java technologies, our developers are equipped to deliver robust and scalable solutions, contributing to the success of your projects
             </p>

             <h5>Efficient Onboarding Process</h5>
             <h5>Smooth Integration Support</h5>
             <h5>Performance-Oriented Solutions</h5>
             <h5>Flexible Engagement Models</h5>
             <h5>Customized Development Approaches</h5>
            <FormButton/>
           </Grid>
           <Grid item xs={12} sm={12} md={6}>
             {" "}
             <img src={JavaMain} alt={""} />
           </Grid>
         </Grid>
       </div>
     </div>
   <JavaB/>
   <ProcessOf/>
   <JavaC/>
  
     
     <Teche/>
   </>
  )
}
