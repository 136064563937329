import React from "react";
import Header from "../../../Home/Header";
// import Drawer  from '../Home/DrawerComp'
// import DrawerComp from '../Home/DrawerComp'
import {Grid } from "@mui/material";
import FormButton from "../../../Home/FormButton";
import FlutterB from "./FlutterB";
import FlutterC from "./FlutterC";
import FlutterD from "./FlutterD";
import ProcessOf from "../../../Services/ProcessOf";

export default function Mobile() {
  return (
    <>
      <Header />
      <div className="ser-main">
        <div className="ser-container">
          <Grid container spacing={3} sx={{ paddingLeft: 3, paddingRight: 3 }}>
            <Grid item xs={12} sm={12} md={6}>
              <h1 className="ser-head">
                Hire
                <span className="color">
                  {" "}
                  Hiring Flutter <br />
                  Developers{" "}
                </span>{" "}
                Rapidly
              </h1>
              <p className="ser-pra">
                Speed up your project timelines by swiftly bringing aboard
                top-notch Flutter web developers. Our streamlined process
                ensures quick access to skilled professionals, empowering your
                team to meet deadlines and exceed project expectations.
              </p>

              <h5>Efficient Onboarding Process</h5>
              <h5>Smooth Integration Support</h5>
              
              <h5>Performance-Oriented Solutions</h5>
              <h5>Flexible Engagement Models</h5>
              <h5>Customized Development Approaches</h5>
              <FormButton/>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              {" "}
              <img src={""} alt={""} />
            </Grid>
          </Grid>
        </div>
      </div>
      <FlutterB/>
      <FlutterC/>
      <ProcessOf/>
      <FlutterD/>
    </>
  );
}
