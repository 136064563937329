import React from 'react'
import { Grid } from '@mui/material'

export default function FlutterC() {
  return (
    <div className="degree-main">
        <div className="degree-container">
            <div className='heading'>
          <h6 className="rea">Expertise, Value, Results</h6>
          <h2>Why Opt for Our Indian Flutter Developers Team?</h2>
          <p className="pe">
          Opting for our Indian Flutter developers team brings a wealth of benefits to your Flutter for Web projects
          </p></div>
          <div className="degree-card">
            <Grid
              container
              spacing={3}
              sx={{ paddingLeft: 3, paddingRight: 3 }}
            >
              <Grid item xs={12} sm={12} md={4}>
                <div className="grid-item-box ">
                  <img src={""} alt={""} />
                  <h3 className="degree-center "> IP Protection</h3>
                  <p className="degree-pra">
                  Rigorous measures are in place to safeguard the integrity and security of your project's innovations.
                  </p>
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={4}>
                <div className="grid-item-box ">
                  <img src={""} alt={""} />
                  <h3 className="degree-center">Complete Data Security</h3>
                  <p className="degree-pra">
                  Robust protocols guarantee comprehensive data protection and confidentiality for your Flutter Web project.
                  </p>{" "}
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={4}>
                {" "}
                <div className="grid-item-box ">
                  <img src={""} alt={""} />
                  <h3 className="degree-center">Flexible Hiring Model</h3>
                  <p className="degree-pra">
                  Enjoy adaptable hiring models that provide seamless flexibility aligned with your web project's needs and resource requirements.
                  </p>{" "}
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="degree-card">
            <Grid
              container
              spacing={3}
              sx={{ paddingLeft: 3, paddingRight: 3 }}
            >
              <Grid item xs={12} sm={12} md={4}>
                <div className="grid-item-box ">
                  <img src={""} alt={""} />
                  <h3 className="degree-center ">100% Project Visibility</h3>
                  <p className="degree-pra">
                  Ensure absolute transparency, granting you full visibility and control throughout the Flutter Web development cycle.
                  </p>
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={4}>
                <div className="grid-item-box ">
                  <img src={""} alt={""} />
                  <h3 className="degree-center"> Vast Talent Pool</h3>
                  <p className="degree-pra">
                  Access a diverse talent pool of skilled Flutter professionals, ensuring scalability for diverse web project requirements.
                  </p>{" "}
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={4}>
                {" "}
                <div className="grid-item-box ">
                  <img src={""} alt={""} />
                  <h3 className="degree-center"> Cost Effective</h3>
                  <p className="degree-pra">
                    
                  Our Indian Flutter developers deliver cost-effective solutions for high-quality Flutter Web development, maintaining excellence and efficiency.
                  </p>{" "}
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="degree-card">
            <Grid
              container
              spacing={3}
              sx={{ paddingLeft: 3, paddingRight: 3 }}
            >
              <Grid item xs={12} sm={12} md={4}>
                <div className="grid-item-box ">
                  <img src={""} alt={""} />
                  <h3 className="degree-center "> No Hidden Charges</h3>
                  <p className="-degree-pra">
                  Transparent pricing ensures clarity and no concealed costs throughout the entire Flutter Web development process.
                  </p>
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={4}>
                <div className="grid-item-box ">
                  <img src={""} alt={""} />
                  <h3 className="degree-center"> Quick Resource Replacement</h3>
                  <p className="degree-pra">
                  Efficient resource management facilitates swift replacement, ensuring project continuity and minimal disruptions when necessary.
                  </p>{" "}
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={4}>
                {" "}
                <div className="grid-item-box ">
                  <img src={""} alt={""} />
                  <h3 className="degree-center">Global Collaboration Advantage</h3>
                  <p className="degree-pra">
                    
                  Leverage the advantage of global collaboration with our Indian Flutter developers, bringing diverse perspectives and expertise to enhance your web projects.
                  </p>{" "}
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      )
}
