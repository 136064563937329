import React from "react";
import { Grid } from "@mui/material";
import VueC from "../../Vue/VueC";
import icon1 from "./NodeImg/NodeJs-01.png";
import icon2 from "./NodeImg/NodeJs-02.png";
import icon3 from "./NodeImg/NodeJs-03.png";
import icon4 from "./NodeImg/NodeJs-04.png";
import icon5 from "./NodeImg/NodeJs-05.png";
import icon6 from "./NodeImg/NodeJs-06.png";
export default function NodeB() {
  return (
    <div className="degree-main cl">
      <div className="degree-container">
        <h6 className="rea">Remote Node Js Experts for Agile Scaling</h6>
        <h2>
          Passionate Node.js Developer for Hire: Unlocking Remote Innovation and
          Excellence in Web Development
        </h2>
        <p className="pe">
          Proficient in server-side JavaScript, I specialize in building
          scalable and high-performance backends. From RESTful APIs to real-time
          applications, my expertise in Node.js ensures efficient and robust
          solutions.
        </p>
        <div className="degree-card reed">
          <Grid container spacing={3} sx={{ paddingLeft: 3, paddingRight: 3 }}>
            <Grid item xs={12} sm={12} md={4}>
              <div className="grid-item-box ">
                <h3 className="degree-center tech">
                  <img src={icon1} alt="" />
                  Expertise and Specialization
                </h3>
                <p className="degree-pra">
                  Our Indian remote NodeJS developers team comprises highly
                  skilled professionals with specialized expertise in NodeJS
                  development. They bring a wealth of experience in building
                  scalable and efficient applications, ensuring the success of
                  your Agile scaling efforts.
                </p>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <div className="grid-item-box ">
                <h3 className="degree-center tech">
                  <img src={icon2} alt={""} />
                  Flexible and Agile Development Approach
                </h3>
                <p className="degree-pra">
                  Our developers follow an adaptive and Agile development
                  approach, allowing for quick iterations and seamless
                  scalability. This flexibility ensures that your project can
                  evolve rapidly to meet changing requirements, a crucial aspect
                  of successful Agile scaling.
                </p>{" "}
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              {" "}
              <div className="grid-item-box ">
                <h3 className="degree-center tech">
                  {" "}
                  <img src={icon3} alt={""} />
                  Cost-Effective Solutions
                </h3>
                <p className="degree-pra">
                  Leveraging our Indian development team offers a cost-effective
                  solution without compromising on quality. You can benefit from
                  competitive pricing while still receiving top-notch NodeJS
                  development services for your scaling initiatives.
                </p>{" "}
              </div>
            </Grid>
          </Grid>
        </div>
        <div className="degree-card">
          <Grid container spacing={3} sx={{ paddingLeft: 3, paddingRight: 3 }}>
            <Grid item xs={12} sm={12} md={4}>
              <div className="grid-item-box ">
                <h3 className="degree-center tech ">
                  {" "}
                  <img src={icon4} alt={""} />
                  Communication and Collaboration
                </h3>
                <p className="degree-pra">
                  Effective communication is paramount in Agile scaling, and our
                  developers prioritize clear and constant communication.
                  Through various collaborative tools and regular updates, we
                  ensure that the development process aligns with your evolving
                  business needs.
                </p>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <div className="grid-item-box ">
                <h3 className="degree-center tech">
                  {" "}
                  <img src={icon5} alt={""} />
                  Proactive Problem Solving
                </h3>
                <p className="degree-pra">
                  Our NodeJS developers in India are known for their proactive
                  approach to problem-solving. They anticipate challenges and
                  provide innovative solutions, ensuring that potential
                  roadblocks are addressed swiftly and effectively during the
                  scaling process.
                </p>{" "}
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              {" "}
              <div className="grid-item-box ">
                <h3 className="degree-center tech">
                  {" "}
                  <img src={icon6} alt={""} />
                  Scalability and Performance Optimization
                </h3>
                <p className="degree-pra">
                  Scaling projects require a deep understanding of system
                  architecture and performance optimization. Our remote NodeJS
                  developers excel in designing scalable solutions and
                  optimizing performance to meet the demands of a growing
                  application.
                </p>{" "}
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <VueC />
    </div>
  );
}
