import React from "react";
import { Grid } from "@mui/material";
import VueC from "../../Vue/VueC";
import icon1 from "./JavaImg/Java-01.png";
import icon2 from "./JavaImg/Java-02.png";
import icon3 from "./JavaImg/Java-03.png";
import icon4 from "./JavaImg/Java-04.png";
import icon5 from "./JavaImg/Java-05.png";
import icon6 from "./JavaImg/Java-06.png";

export default function JavaB() {
  return (
    <div className="degree-main cl">
      <div className="degree-container">
        <h6 className="rea">Remote Java Experts for Agile Scaling</h6>
        <h2>
          Skilled Remote Java Developer: Transforming Code into Exceptional
          Software Solutions
        </h2>
        <p className="pe">
          We bring proficiency in building robust, scalable, and
          high-performance applications. Leveraging my in-depth knowledge of
          Java technologies, I am committed to delivering top-notch solutions
          tailored to your unique requirements. Let's collaborate remotely to
          elevate your software development projects to new heights!
        </p>
        <div className="degree-card reed">
          <Grid container spacing={3} sx={{ paddingLeft: 3, paddingRight: 3 }}>
            <Grid item xs={12} sm={12} md={4}>
              <div className="grid-item-box ">
                <h3 className="degree-center tech">
                  <img src={icon1} alt="" />
                  Expertise in Java Technologies
                </h3>
                <p className="degree-pra">
                  Our remote Java developers bring extensive expertise in Java
                  technologies, including proficiency in frameworks like Spring
                  and Hibernate. This ensures that your project benefits from
                  the latest advancements and best practices in Java
                  development.
                </p>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <div className="grid-item-box ">
                <h3 className="degree-center tech">
                  <img src={icon2} alt={""} />
                  Agile Development Methodology
                </h3>
                <p className="degree-pra">
                  Embracing Agile methodologies, our remote Java developers
                  prioritize adaptability and collaboration. This facilitates
                  quicker response to changes in project requirements, ensuring
                  a more flexible and efficient development process.
                </p>{" "}
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              {" "}
              <div className="grid-item-box ">
                <h3 className="degree-center tech">
                  {" "}
                  <img src={icon3} alt={""} />
                  Scalability and Resource Flexibility
                </h3>
                <p className="degree-pra">
                  With a remote team, scalability is not a concern. You can
                  easily scale your development team up or down based on project
                  needs. This flexibility allows for optimal resource allocation
                  and cost-effectiveness.
                </p>{" "}
              </div>
            </Grid>
          </Grid>
        </div>
        <div className="degree-card">
          <Grid container spacing={3} sx={{ paddingLeft: 3, paddingRight: 3 }}>
            <Grid item xs={12} sm={12} md={4}>
              <div className="grid-item-box ">
                <h3 className="degree-center tech ">
                  {" "}
                  <img src={icon4} alt={""} />
                  Continuous Integration and Continuous Deployment (CI/CD)
                </h3>
                <p className="degree-pra">
                  Our Java developers are well-versed in implementing CI/CD
                  pipelines, enabling rapid and reliable software releases. This
                  ensures that your applications are continuously tested,
                  integrated, and deployed, resulting in a streamlined
                  development cycle.
                </p>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <div className="grid-item-box ">
                <h3 className="degree-center tech">
                  {" "}
                  <img src={icon5} alt={""} />
                  Effective Communication and Collaboration
                </h3>
                <p className="degree-pra">
                  Despite the geographical distance, our remote Java developers
                  prioritize effective communication. Leveraging modern
                  collaboration tools, they ensure seamless interaction,
                  fostering a sense of teamwork and shared goals.
                </p>{" "}
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              {" "}
              <div className="grid-item-box ">
                <h3 className="degree-center tech">
                  {" "}
                  <img src={icon6} alt={""} />
                  Quality Assurance and Testing
                </h3>
                <p className="degree-pra">
                  Quality is paramount in software development. Our remote Java
                  developers follow rigorous testing practices, including unit
                  testing, integration testing, and automated testing, to
                  deliver high-quality, robust applications.
                </p>{" "}
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <VueC />
    </div>
  );
}
