import React from "react";
import Header from "../Home/Header";
import { Grid } from "@mui/material";
import AngB from "./Ang/AngB";
import AngC from "./Ang/AngC";
import ProcessOf from "../Services/ProcessOf";
import AngD from "./Ang/AngD";
import Teche from "../Technology/Teche";
import FormButton from "../Home/FormButton";
import AngImg from "./angularJs-banner.png"
import {Helmet} from "react-helmet"


export default function Angular() {
 
  return (
    <>
     <Helmet>
                <meta charSet="utf-8" />
                <title>Angular JS  | Destino Infotech</title>
                <meta name="description" content="Helmet application" />
                <link rel="canonical" href="http://mysite.com/example" />
            </Helmet>
      <Header />
      <div className="ser-main">
        <div className="ser-container">
          <Grid container spacing={3} sx={{ paddingLeft: 3, paddingRight: 3 }}>
            <Grid item xs={12} sm={12} md={6}>
              <h1 className="ser-head">
                Hire
                <span className="color">
                  {" "}
                  AngularJS <br />
                  Developers{" "}
                </span>{" "}
                Swiftly
              </h1>
              <p className="ser-pra">
                Accelerate your project timelines by swiftly engaging top-tier
                AngularJS developers. Our streamlined process ensures rapid
                access to skilled professionals, empowering your team to meet
                deadlines and surpass project expectations with AngularJS
                expertise.
              </p>

              <h5>Efficient Onboarding Process</h5>
              <h5>Smooth Integration Support</h5>
              <h5>Smooth Integration Support</h5>
              <h5>Flexible Engagement Models</h5>
              <h5>Flexible Engagement Models</h5>
          
       
     

         
            

        
      <FormButton/>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              {" "}
              <img src={AngImg} alt={""} />
            </Grid>
          </Grid>
        </div>
      </div>
      <AngB />
      <AngC />
      <ProcessOf />
      <AngD/>
      <Teche />
      {/* <Search /> */}
      {/* <Footer /> */}
    </>
  );
}
