import React from "react";
import "./Technology.scss";
import img1 from "./T-img/Certified Professionals.png";
import img2 from "./T-img/Clients.png";
import img3 from "./T-img/Successful Projects.png";
// import img4 from "./T-img/Global Presence.png";
import img5 from "./T-img/Years of Experience.png";
import Footer from "../Home/Footer";
import Search from "../Home/Search";

export default function Teche() {
  
  return (
    
    <>
      <div className="teche-main">
        <div className="teche-container">
          <h6 className="rea">Proven Expertise</h6>
          <div className="teche-head">
            <h2>Why Choose Destino for Offshore Software Development</h2>
          </div>
          <div className="main-car">
            <div className="main-c">
              <div className="year">
                <img src={img5} alt="" />{" "}
              </div>
              <div className="hp">
                {" "}
                <h3>10+</h3>
                <p>Years of Experience</p>
              </div>
            </div>
            <div className="main-c">
              <div className="year">
                <img src={img1} alt="" />{" "}
              </div>
              <div className="hp">
                {" "}
                <h3>200+</h3>
                <p>Certified Professionals</p>
              </div>
            </div>
            <div className="main-c">
              <div className="year">
                <img src={img3} alt="" />{" "}
              </div>
              <div className="hp">
                {" "}
                <h3>500+</h3>
                <p>Successful Projects</p>
              </div>
            </div>
            <div className="main-c">
              <div className="year">
                <img src={img2} alt="" />{" "}
              </div>
              <div className="hp">
                {" "}
                <h3>150+</h3>
                <p>Clients</p>
              </div>
            </div>
          </div>
          
           
           
          </div>
        </div>
    
      <Search />
      <Footer />
    </>
  );
}
