import React from "react";
import { Grid } from "@mui/material";
import { Helmet } from "react-helmet";
import Header from "../../../Home/Header";
import FormButton from "../../../Home/FormButton";
import Teche from "../../../Technology/Teche";
import PythonB from "./PythonB";
import PythonC from "./PythonC";
import ProcessOf from "../../../Services/ProcessOf";
import PythonMain from "./Pythonimg/Python-banner.png";

export default function Python() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Python | Destino Infotech</title>
        <meta name="description" content="Helmet application" />
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <Header />
      <div className="ser-main">
        <div className="ser-container">
          <Grid container spacing={3} sx={{ paddingLeft: 3, paddingRight: 3 }}>
            <Grid item xs={12} sm={12} md={6}>
              <h1 className="ser-head">
                Hire
                <span className="color">
                  {" "}
                  Python <br />
                  Developers{" "}
                </span>{" "}
              </h1>
             
              <p className="ser-pra">
                Facilitate the swift integration of top-tier Python developers
                into your project, boosting your team's efficiency and ensuring
                timely project completion. Our streamlined hiring process
                guarantees quick access to skilled professionals well-versed in
                Python, empowering your team to meet deadlines and deliver
                high-quality solutions. Leverage the versatility of Python for a
                wide range of applications, from web development to data
                science.
              </p>
              <h5>Efficient Onboarding Process</h5>
              <h5>Smooth Integration Support</h5>
              <h5>Performance-Oriented Solutions</h5>
              <h5>Flexible Engagement Models</h5>
              <h5>Customized Development Approaches</h5>
              <FormButton />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              {" "}
              <img src={PythonMain} alt={""} />
            </Grid>
          </Grid>
        </div>
      </div>
      <PythonB />
      <ProcessOf/>
      <PythonC />
     

      <Teche />
    </>
  );
}
