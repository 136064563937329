// Create a new file, e.g., TechnologiesDropdown.jsx
// import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import React from "react";
import { Paper, Typography } from "@mui/material";
// import Link from "@mui/material/Link";
import "./App.scss";
import Grid from "@mui/material/Grid";
// import Flutter from "../TechnologyHover/Mobile/Flutter/Flutter";
import { Link } from "react-router-dom";

const Model = ({ position, onMouseEnter, onMouseLeave, isModelVisible }) => {
  // You can customize the content of the dropdown here
  return isModelVisible ? (
    <Paper
      id="technologies-dropdown"
      style={{
        position: "absolute",
        top: position.top,
        left: 337,
        width: "34%",
        padding: "10px",
        zIndex: 1,
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <div>
            <Typography>
              <>
                {/* <div className="Mo">
                  <h2>MOBILE</h2>
                  <hr />
                </div> */}
              </>
            </Typography>
             <div className="Mo-link">
              {/* <h3>
                <Link to="/Flutter" className="lin">
                  Flutter
                </Link>
              </h3> */}
             {/* <h3>
                <Link to="#" className="lin">
                  iOS
                </Link>
              </h3>
              <h3>
                <Link to="#" className="lin">
                  Android
                </Link>
              </h3>*/}
               </div> 
               </div>{" "}
                </Grid>

            <Grid item xs={3}>
             <div>
            <Typography>
              <>
                {/* <div className="Mo">
                  <h2>FRONTEND</h2>
                  <hr />
                </div> */}
              </>
            </Typography>
            <div className="Mo-link">
              <h3>
                <Link to="/Angular" className="lin">
                  AngularJS
                </Link>
              </h3>
              <h3>
                <Link to="/React" className="lin">
                  ReactJS
                </Link>
              </h3>
              <h3>
                <Link to="/Vue" className="lin">
                  VueJS
                </Link>
              </h3>
              {/* <h3>
                <Link to="#" className="lin">
                  Electron
                </Link>
              </h3>
              <h3>
                <Link to="#" className="lin">
                  JavaScript
                </Link>
              </h3>
              <h3>
                <Link to="#" className="lin">
                  Flutter For Web
                </Link>
              </h3> */}
            </div>
          </div>
        </Grid>
        <Grid item xs={3}>
          <div>
            <Typography>
              <>
                {/* <div className="Mo">
                  <h2>BACKEND</h2>
                  <hr />
                </div> */}
              </>
            </Typography>
            <div className="Mo-link">
              <h3>
                <Link to="/Node" className="lin">
                  NodeJS
                </Link>
              </h3>
              <h3>
                <Link to="/Python" className="lin">
                  Python
                </Link>
              </h3>
              <h3>
                <Link to="/Java" className="lin">
                  Java
                </Link>
              </h3>
              {/* <h3>
                <Link to="#" className="lin">
                  PHP
                </Link>
              </h3>
              <h3>
                <Link to="#" className="lin">
                  Dot Net
                </Link>
              </h3> */}
            </div>
          </div>
        </Grid>
        {/* <Grid item xs={3}>
          <div>
            <Typography>
              <>
                <div className="Mo">
                  <h2>FULL STACK</h2>
                  <hr />
                </div>
              </>
            </Typography>
            <div className="Mo-link">
              <h3>
                <Link to="#" className="lin">
                  MEAN
                </Link>
              </h3>
              <h3>
                <Link to="#" className="lin">
                  MERN
                </Link>
              </h3>
            </div>
          </div>
        </Grid> */}
      </Grid>
    </Paper>
  ) : null;
};

export default Model;
