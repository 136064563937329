import React from "react";
import { Grid } from "@mui/material";

export default function NodeC() {
  return (
    <div>
      <div className="degree-main">
        <div className="degree-container">
          <h6 className="rea">Popular Vue.js Pairings</h6>
          <h2>Why Opt for Our Indian Remote NodeJS Developers Team</h2>
          <p className="pe">
            In a globalized digital landscape, choosing our Indian remote NodeJS
            developers team provides several benefits
          </p>
          <div className="degree-card">
            <Grid
              container
              spacing={3}
              sx={{ paddingLeft: 3, paddingRight: 3 }}
            >
              <Grid item xs={12} sm={12} md={6}>
                <div className="techd  ">
                  <h3 className="degree-center techa">
                    <span className="dot" />
                    Time Zone Compatibility
                  </h3>
                  <p className="degree-pra left">
                    Benefit from overlapping working hours between your team and
                    our developers, ensuring efficient communication and
                    collaboration.
                  </p>
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <div className="techd ">
                  <h3 className="degree-center techa">
                    {" "}
                    <span className="dot" />
                    Cost Savings
                  </h3>
                  <p className="degree-pra left">
                    Take advantage of the cost-effectiveness of outsourcing to
                    India, reducing development expenses while maintaining
                    high-quality standards.
                  </p>{" "}
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                {" "}
                <div className="techd">
                  <h3 className="degree-center techa">
                    {" "}
                    <span className="dot" />
                    Vast Talent Pool
                  </h3>
                  <p className="degree-pra left">
                    Tap into a vast pool of skilled NodeJS developers in India,
                    allowing you to select the best-fit professionals for your
                    Agile scaling project.
                  </p>{" "}
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <div className="techd ">
                  <h3 className="degree-center techa">
                    {" "}
                    <span className="dot" />
                    Proven Track Record
                  </h3>
                  <p className="degree-pra left">
                    Rely on our developers' proven track record of successful
                    NodeJS projects, demonstrating their capability to handle
                    complex scaling challenges.
                  </p>{" "}
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                {" "}
                <div className="techd ">
                  <h3 className="degree-center techa">
                    {" "}
                    <span className="dot" />
                    Cultural Compatibility
                  </h3>
                  <p className="degree-pra left">
                    Our developers understand and adapt to various business
                    cultures, fostering a harmonious and collaborative working
                    relationship.
                  </p>{" "}
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                {" "}
                <div className="techd">
                  <h3 className="degree-center techa">
                    {" "}
                    <span className="dot" />
                    Quality Assurance
                  </h3>
                  <p className="degree-pra left">
                    Our team emphasizes rigorous quality assurance practices,
                    ensuring the delivery of robust and reliable NodeJS
                    applications.
                  </p>{" "}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                {" "}
                <div className="techd">
                  <h3 className="degree-center techa">
                    {" "}
                    <span className="dot" />
                    Dedicated Project Management
                  </h3>
                  <p className="degree-pra left">
                    Enjoy the benefits of a dedicated project manager who
                    oversees the development process, ensuring timelines and
                    milestones are met.
                  </p>{" "}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                {" "}
                <div className="techd">
                  <h3 className="degree-center techa">
                    {" "}
                    <span className="dot" />
                    Security Measures
                  </h3>
                  <p className="degree-pra left">
                    Our team follows stringent security measures to protect your
                    sensitive data and intellectual property throughout the
                    development lifecycle.
                  </p>{" "}
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
}
