import React  from "react";
import Header from "../Home/Header";
import { Grid } from "@mui/material";
import VueB from "./Vue/VueB";
import VueD from "./Vue/VueD";
import VueC from "./Vue/VueC";
import ProcessOf from "../Services/ProcessOf";
import Teche from "../Technology/Teche";
import VueImg from './vueJS-banner.png';
import {Helmet} from "react-helmet"
import FormButton from "../Home/FormButton";


export default function Vue() {
  return (
    <>
     <Helmet>
                <meta charSet="utf-8" />
                <title>Vue JS  | Destino Infotech</title>
                <meta name="description" content="Helmet application" />
                <link rel="canonical" href="http://mysite.com/example" />
            </Helmet>
      <Header />
      <div className="ser-main">
        <div className="ser-container">
          <Grid container spacing={3} sx={{ paddingLeft: 3, paddingRight: 3 }}>
            <Grid item xs={12} sm={12} md={6}>
              <h1 className="ser-head">
                Hire
                <span className="color">
                  {" "}
                  Vue JS <br />
                  Developers{" "}
                </span>{" "}
              </h1>
              <p className="ser-pra">
                Our skilled Vue.js developers are adept at aiding you in
                achieving your tech objectives by constructing efficient,
                optimized, and responsive web applications cost-effectively.
                Here's a glimpse of how they can assist you
              </p>

              <h5>Pre-vetted Experts</h5>
              <h5>7 Days Free Trial</h5>
              <h5>2X faster Delivery</h5>
              <h5>Agile Development Methodology</h5>
              <h5>Easy team scaling</h5>
             <FormButton/>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              {" "}
              <img src={VueImg} alt={""} />
            </Grid>
          </Grid>
        </div>
      </div>
      <VueB/>
      <VueC />
      <ProcessOf/>
      <VueD/>
      <Teche/>
    </>
  );
}
