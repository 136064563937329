import React from "react";
import "./Services.scss";
import { Grid } from "@mui/material";
// import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import Re from "./S-Img/Reactjs.png";
import Ang from "./S-Img/Angular_71x53.png";
import Java from "./S-Img/Java.png";
import Net from "./S-Img/Net.png";
import flt from "./S-Img/Flutter.png";
import Node from "./S-Img/nodejs.png";
import Obj from "./S-Img/ObjectiveC.png";
import JavaS from "./S-Img/JavaScript.png";
import Py from "./S-Img/pyhton.png";

export default function Degree() {
  return (
    <div className="degree-main">
      <div className="degree-container">
        <div className="heading">
          <h6 className="rea">
            Comprehensive Development Solutions at Your Fingertips
          </h6>
          <h2>Hire Developers For 360-degree Development Services.</h2>
          <p className="pe">
            With our holistic approach to development, Destino offers a
            360-degree suite of services designed to cover every aspect of your
            project. From ideation to execution, our team delivers a seamless
            journey, incorporating cutting-edge technologies and strategic
            insights. Trust us to handle every facet, ensuring a robust and
            all-encompassing development experience.
          </p>
        </div>
        <div className="degree-card">
          <Grid container spacing={3} sx={{ paddingLeft: 3 }}>
            <Grid item xs={12} sm={12} md={4} className="reed">
              <div className="grid-item-box ">
                <img src={Ang} alt={Re} />
                <h4 className="degree-center "> Hire AngularJs Developers</h4>
                <p className="degree-pra">
                  Leverage expertise in AngularJS for robust, scalable, and
                  dynamic front-end development. Ensure seamless integration and
                  interactive user interfaces with AngularJS specialists.
                  Harness the power of MVC architecture for organized and
                  efficient web app development.
                </p>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={4} className="reed">
              <div className="grid-item-box ">
                <img src={Re} alt={Re} />
                <h4 className="degree-center">Hire ReactJs Developers</h4>
                <p className="degree-pra">
                  Build high-performance, reusable UI components with ReactJS
                  experts. Accelerate development cycles and achieve optimal
                  rendering for complex interfaces. Benefit from React's virtual
                  DOM for enhanced app performance and responsiveness.
                </p>{" "}
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={4} className="reed">
              {" "}
              <div className="grid-item-box ">
                <img src={Node} alt={Re} />
                <h4 className="degree-center"> Hire NodeJs Developers</h4>
                <p className="degree-pra">
                  Enable rapid and scalable back-end development using NodeJS.
                  Facilitate real-time applications and microservices
                  architecture with NodeJS proficiency. Leverage event-driven,
                  non-blocking I/O for efficient server-side development.
                </p>{" "}
              </div>
            </Grid>
          </Grid>
        </div>
        <div className="degree-card">
          <Grid container spacing={3} sx={{ paddingLeft: 3 }}>
            <Grid item xs={12} sm={12} md={4} className="reed">
              <div className="grid-item-box ">
                <img src={JavaS} alt={Re} />
                <h4 className="degree-center "> Hire JavaScript Developers</h4>
                <p className="degree-pra">
                  Access versatile expertise in JavaScript for comprehensive web
                  development. Utilize full-stack capabilities to create dynamic
                  and interactive web applications. Ensure cross-platform
                  compatibility and responsive design implementation.
                </p>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={4} className="reed">
              <div className="grid-item-box ">
                <img src={Py} alt={Re} />
                <h4 className="degree-center"> Hire Python Developers</h4>
                <p className="degree-pra">
                  Leverage Python's simplicity and versatility for rapid
                  application development. Access AI, machine learning, and data
                  analysis capabilities with Python specialists. Achieve robust,
                  scalable solutions with Python's extensive libraries and
                  frameworks. .
                </p>{" "}
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={4} className="reed">
              {" "}
              <div className="grid-item-box ">
                <img src={Obj} alt={Re} />
                <h4 className="degree-center"> Hire IOS Developers</h4>
                <p className="degree-pra">
                  Create user-centric, intuitive iOS applications with
                  experienced developers. Ensure adherence to Apple's guidelines
                  for a seamless App Store submission process. Leverage
                  expertise in Swift and Objective-C for robust iOS app
                  development.
                </p>{" "}
              </div>
            </Grid>
          </Grid>
        </div>
        <div className="degree-card">
          <Grid container spacing={3} sx={{ paddingLeft: 3 }}>
            <Grid item xs={12} sm={12} md={4} className="reed">
              <div className="grid-item-box ">
                <img src={flt} alt={Re} />
                <h4 className="degree-center "> Hire Flutter Developers</h4>
                <p className="-degree-pra">
                  Develop visually stunning, cross-platform mobile applications
                  with Flutter experts. Benefit from hot reload features,
                  reducing development time and enhancing productivity. Ensure
                  consistent UI across multiple platforms with Flutter's single
                  codebase. .
                </p>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={4} className="reed">
              <div className="grid-item-box ">
                <img src={Net} alt={Re} />
                <h4 className="degree-center"> Hire .Net Developers</h4>
                <p className="degree-pra">
                  Access comprehensive Microsoft technology expertise for
                  scalable enterprise solutions. Leverage the robustness of the
                  .NET framework for secure and high-performance applications.
                  Ensure seamless integration with existing systems and cloud
                  services. .
                </p>{" "}
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={4} className="reed">
              {" "}
              <div className="grid-item-box ">
                <img src={Java} alt={Re} />
                <h4 className="degree-center"> Hire Java Developers</h4>
                <p className="degree-pra">
                  Utilize Java's versatility for building secure, scalable, and
                  platform-independent applications. Benefit from Java's
                  extensive ecosystem and libraries for varied development
                  needs. Leverage expertise in Java frameworks like Spring and
                  Hibernate for efficient development. .
                </p>{" "}
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}
